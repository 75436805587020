import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class DepService {
    static async doiOne(msisdn, productId, serviceId) {
        const response = await axios.post(`${environmentConfig.depService}/upsell/doi-one`, {
            msisdn,
            productId: productId,
            serviceId,
            externalRef: 1234,
            channel: "WAP",
            doiChannel: "WAP_REDIRECT"
        })
        return response.data;
    }

    static async subscription(msisdn, productId, subscriptionId, serviceId, campaignId, googleClickId, clickId) {
        const response = await axios.post(`${environmentConfig.depService}/upsell/subscribe`, {
                msisdn: msisdn,
                productId: productId,
                subscriptionId: subscriptionId,
                serviceId: serviceId,
                campaignId: campaignId,
                googleClickId: googleClickId,
                clickId: clickId,
            },
        )
        return response.data
    }

    static async checkUserSubscription(msisdn, serviceId) {
        const response = await axios.post(`${environmentConfig.depService}/upsell/check-user-subscription`, {
                msisdn: msisdn,
                serviceId: serviceId,
            },
        )
        return response.data
    }
}
