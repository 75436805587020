<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="header-bg">
        <img class="col-12" src="./assets/header-nav.webp" alt="">
      </div>
      <LoadingOverlay v-if="this.isLoading"/>
      <router-view/>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {setBrandConfig} from "./functions/setBrandConfig";
import getCampaignParametersFromUrl from "./functions/getCampaignParametersFromUrl";
import LoadingOverlay from "@/views/components/LoadingOverlay";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['brandConfig', 'isLoading'])
  },
  methods: {
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig.brand}/assets/game-logo.webp`)
    },
    setTabName() {
      document.title = this.brandConfig.gameTitle;
    },
  },
  beforeMount() {
    setBrandConfig();
    this.setFavicon()
    this.setTabName();
  },
}
</script>

<style>
@import './assets/main.css';

.header-bg {
  height: 83px;
}

.app-container {
  position: relative;
  background-image: url("assets/bg-main.webp");
  background-size: cover;
  min-height: 100vh;
  color: #000000;
  overflow-y: scroll;
  overflow-x: hidden;
}

.app-container::-webkit-scrollbar {
  display: none;
}

.app-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
