import { createRouter, createWebHistory } from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
import {getBrandConfigFromGameParameter} from "@/functions/setBrandConfig";

const routes = [
  {
    path: '/',
    redirect: getBaseRoute(),
  },
  {
    path: '/opt-in-confirmation',
    name: 'DoiTwo',
    component: () => import("../views/pages/DoiTwo"),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import("../views/pages/ThankYou"),
  },
  {
    path: '/wifi',
    name: 'OnWifi',
    component: () => import("../views/pages/errors/OnWifi"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
})

function getBaseRoute(){
  const domain = window.origin;
  const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : getBrandConfigFromGameParameter();
  return brandConfig.baseRoute;
}

export default router
